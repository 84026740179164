import React, { Component } from "react";
import "./css/App.css";
import Select from "react-select";
import Container from "react-bootstrap/Container";
import StaticData from "./StaticData";
import Menu from "./components/Menu";
import Header from "./components/Header";
import Footer from "./components/Footer";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import ReactHtmlParser from "react-html-parser";
import queryString from "query-string";
import $ from "jquery";
import serviceauth from "./auth/serviceauth";
import { properties } from "./properties.js";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Helmet } from "react-helmet";

class RequestForm extends Component {
  changemajor_text =
    "Please choose a new major above to see the Change of Major requirements.";

  endpoint = properties.endpoint;
  environment = properties.environment;
  wpcareyUrl = properties.wpcareyUrl;

  initialState = {
    new_subplan: null,
    new_campus: null,
    contact_phone: "",
    major_change_source: null,
    why: null,
    additional_info: "",
  };

  constructor(props) {
    super(props);

    this.state = {
      asurite:
        sessionStorage.getItem(serviceauth.SS_VIEW_AS) != null
          ? sessionStorage.getItem(serviceauth.SS_VIEW_AS)
          : sessionStorage.getItem(serviceauth.SS_ASURITE),
      admin: this.environment !== "prod" ? StaticData.adminList : [],
      term: "",
      emplid: "",
      first_name: "",
      last_name: "",

      major_to_change: "",
      major_to_change_title: "Which major would you like to change?",

      new_major: null,

      new_major_title: "Which major would you like to choose?",
      new_major_college: "", // major college
      new_major_wp_carey: "", // if the major is in w.p. carey, request go to wp carey if is.

      new_subplan: null,
      new_subplan_title: "Which subplan would you like to choose?",

      new_campus: null,
      new_campus_title: "Which campus do you want to attend?",

      requirements_check: "Yes",
      requirements_check_title: "Do you currently meet these requirements?",

      double_check: "I am sure",
      double_check_title:
        "How sure are you that you would like to change this major?",

      contact_phone: "",
      contact_phone_title: "At which phone number can you be reached?",

      major_change_source: null,
      major_change_source_title: "How did you hear about this new major?",

      why: null,
      why_title: "Why do you want to change your current major?",

      additional_info: "",
      additional_info_title:
        "Is there any other additional information that you would like us to know?",

      // salesforceEmail: "",
      confirmation: "",

      studentMajors: [], // student current majors from Mulesoft API
      hits: [], // majors list from Degree search elasticsearch
      ds_major_list: null, // major dropdown
      campus_list: [{ value: null, label: "No campuses to display" }], // campus dropdown when a major is choosen
      subplans: null, // subplan dropdownlist

      changemajor_text: this.changemajor_text,

      new_major_error: "",
      new_subplan_error: "",
      new_campus_error: "",
      requirements_check_error: "",
      double_check_error: "",
      contact_phone_error: "",
      major_change_source_error: "",
      why_error: "",
      error_message: "",
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleChangeSelect = this.handleChangeSelect.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleSubmitWPCarey = this.handleSubmitWPCarey.bind(this);
  }

  clearProgressSessionStorage() {
    sessionStorage.removeItem("progress");
    sessionStorage.removeItem("storedAsurite");
    sessionStorage.removeItem("emailBody");
    sessionStorage.removeItem("emailBodyJson");
    sessionStorage.removeItem("plancode");
    sessionStorage.removeItem("subplan");
    sessionStorage.removeItem("campus");
    sessionStorage.removeItem("storedToken");
    sessionStorage.removeItem("emplid");
  }
  getNextTerm() {
    var date = new Date();
    var month = date.getMonth();
    var year = date.getFullYear().toString().substr(2, 2);
    var nextYear = (date.getFullYear() + 1).toString().substr(2, 2);

    if (month >= 0 && month <= 6) {
      this.setState({ term: "2" + year + "7" });
    } else this.setState({ term: "2" + nextYear + "1" });
  }

  //===============GET STUDENT DATA FROM MULESOFT API ========

  async getStudentMajor(acadPlan) {
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        authorizationToken: sessionStorage.getItem(serviceauth.SS_JWT_TOKEN),
        api: "student-major",
        asurite: this.state.asurite,
      }),
    };
    console.log("get studenmajor for ausrite: " + this.state.asurite);

    if (this.state.asurite) {
      await fetch(this.endpoint, requestOptions)
        .then((response) => response.json())
        .then((data) => {
          console.log("student:" + JSON.stringify(data.studentMajor));

          if (data.studentMajor) {
            $(".loadWait").hide();
            $(".submitWait").hide();
            $(".resubmitWait").hide();
            var show_form = false;

            data.studentMajor.forEach((studentMajor) => {
              this.setState({ emplid: studentMajor.emplid });
              this.setState({ first_name: studentMajor.firstName });
              this.setState({ last_name: studentMajor.lastName });
              var careers = studentMajor.careers;

              if (careers) {
                careers.forEach((careerr) => {
                  if (careerr.career && careerr.career === "UGRD") {
                    var career = careerr.career;
                    var plans = careerr.plans;
                    this.setState({ career: career });
                    this.setState({ studentMajors: plans });

                    if (plans) {
                      plans.forEach((plan) => {
                        if (
                          plan.degree !== "AA" &&
                          plan.degree !== "AS" && //block Military studies student and Associateds in Science from access any form.
                          (plan.planType === "MAJ" ||
                            plan.planType === "PRE") &&
                          plan.planCode !== "BALGMARAS" &&
                          plan.planCode !== "LSORGLAA" &&
                          plan.planCode !== "LSMILSTAA" &&
                          plan.planCode !== "PPEMEAS" &&
                          plan.planCode !== "PPEMECERT"
                        ) {
                          if (show_form === false) {
                            this.setState({
                              major_to_change:
                                plan.transcriptDescription + ", " + plan.degree,
                            });
                            show_form = true;
                            this.getElasticSearch(acadPlan);
                          }
                        }
                      });
                    }
                  }
                });
              }
            });

            if (show_form === false) {
              window.location.href = "/notice";
            }
          } else {
            if (JSON.stringify(data).includes("enrolled")) {
              window.location.href = "/ineligible";
            } else {
              window.location.href = "/notice";
            }
          }
        })
        .catch((error) => console.log("Error student major! " + error.message));
    }
  }

  // =============== GET PLAN DATA FROM ELASTICSEARCH API =========
  async getElasticSearch(acadPlan) {
    console.log("get elastic for plan=" + acadPlan);
    console.log("environment = " + this.environment);
    console.log("next term: " + this.state.term);
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        api: "elasticsearch",
        elasticIndex: "program-undergrad-false",
        authorizationToken: sessionStorage.getItem(serviceauth.SS_JWT_TOKEN),
        outputParams: [
          "DEGREE",
          "ACADPLAN",
          "CAMPUSDESCR",
          "MAJORDESCRIPTION",
          "CHANGEMAJOR",
          "MAJORMAPSUBPLANS",
          "COLLEGEURLANDDESCR",
          // "DARSAUDIT",
        ],
        matchQuery: this.state.term,
        matchFields: ["APPLYDEADLINES.KEY"],
      }),
    };

    await fetch(this.endpoint, requestOptions)
      .then((response) => response.json())
      .then((data) => {
        console.log("data=" + JSON.stringify(data));

        data.sort(function (a, b) {
          a = a._source.MAJORDESCRIPTION.toLowerCase();
          b = b._source.MAJORDESCRIPTION.toLowerCase();

          return a < b ? -1 : a > b ? 1 : 0;
        });

        this.setState({ hits: data });
        var majorList = [];
        data.forEach((hit) => {
          var major = {
            value: hit._source.ACADPLAN,
            label:
              hit._source.MAJORDESCRIPTION +
              ", " +
              hit._source.DEGREE +
              " [" +
              hit._source.ACADPLAN +
              "]",
          };
          // console.log("major=" + major);
          if (hit._source.ACADPLAN === acadPlan) {
            this.setState({ new_major: major });
          }
          if (
            major.value !== "LALAWPRE" &&
            major.value !== "9HOHONALL" &&
            major.value !== "LSMILSTAA" &&
            major.value !== "BALGMARAS" &&
            major.value !== "LSORGLAA"
          ) {
            majorList.push(major);
          }
        });
        this.setState({ ds_major_list: majorList });

        if (acadPlan) {
          this.getCollegeLocationsByAcadPlan(data, acadPlan);
        }
      })
      .catch((error) => console.log("Error elastic! " + error.message));
  }

  //===========GET SUBPLAN AND LOCATION BY PLAN CODE FROM PLAN DATA (FROM ELASTICSEARCH)========================

  getCollegeLocationsByAcadPlan(hits, acadPlan) {
    console.log("getLocation plan=" + acadPlan);
    hits.forEach((hit) => {
      if (hit._source.ACADPLAN === acadPlan) {
        var campus_list = [];
        if (hit._source.CAMPUSDESCR) {
          hit._source.CAMPUSDESCR.forEach((campus) => {
            var campu = { value: campus.KEY, label: campus.VALUE };
            campus_list.push(campu);
          });
          this.setState({ campus_list });
        } else {
          this.setState({ campus_list: null });
        }

        this.setState({ new_major_college: hit._source.COLLEGEURLANDDESCR });

        if (hit._source.COLLEGEURLANDDESCR.indexOf("Carey") !== -1) {
          this.setState({ new_major_wp_carey: "true" });
        } else {
          this.setState({ new_major_wp_carey: "" });
        }

        if (hit._source.CHANGEMAJOR) {
          this.setState({ changemajor_text: hit._source.CHANGEMAJOR });
        } else {
          this.setState({ changemajor_text: this.changemajor_text });
        }

        this.setState(this.initialState);
        this.setState({ subplans: null });

        if (campus_list.length === 1) {
          this.setState({ new_campus: campus_list[0] });
          var mmSubplanCampus = "ALL";
          if (campus_list[0] === "ONLNE") {
            mmSubplanCampus = "ONLINE";
          }
          this.getSubplanByAcadPlanCampus(hits, acadPlan, mmSubplanCampus);
        }
      }
    });
  }

  getSubplanByAcadPlanCampus(hits, acadPlan, mmSubplanCampus) {
    console.log("mmSubplancampus : " + mmSubplanCampus);
    console.log("getLocation plan=" + acadPlan);
    hits.forEach((hit) => {
      if (hit._source.ACADPLAN === acadPlan) {
        if (mmSubplanCampus && hit._source.MAJORMAPSUBPLANS) {
          console.log("get subplan info");
          var subplans = [];
          hit._source.MAJORMAPSUBPLANS.forEach((subplan) => {
            var subplanKeyArr = subplan.KEY.split(":");
            console.log("subplanKeyArr: " + subplan.KEY);
            var subplanKey = subplanKeyArr[0];
            var subplanCampus = subplanKeyArr[1];
            if (subplanCampus) {
              if (subplanCampus === mmSubplanCampus) {
                var subpln = { value: subplanKey, label: subplan.VALUE };
                subplans.push(subpln);
              }
            } else {
              subpln = { value: subplan.KEY, label: subplan.VALUE };
              subplans.push(subpln);
            }
          });

          if (subplans.length > 0) this.setState({ subplans });
          else {
            this.setState({ subplans: null });
          }

          if (subplans.length === 1) {
            this.setState({ new_subplan: subplans[0] });
          } else {
            this.setState({ new_subplan: null });
          }
        }
      }
    });
  }

  //=============GET SALESFORCE EMAIL ADDRESS FROM MULESOFT API ======================

  // getSalesforceEmail(plancode) {
  //   console.log("plancode=" + plancode);

  //   const requestOptions = {
  //     method: "POST",
  //     headers: {
  //       "Content-Type": "application/json",
  //     },
  //     body: JSON.stringify({
  //       authorizationToken: sessionStorage.getItem(serviceauth.SS_JWT_TOKEN),
  //       api: "get-email",
  //       plancode: plancode,
  //     }),
  //   };

  //   fetch(this.endpoint, requestOptions)
  //     .then((response) => response.json())
  //     .then((data) => {
  //       console.log(
  //         "salesforce email:" + JSON.stringify(data.changeMajor.email)
  //       );
  //       this.setState({ salesforceEmail: data.changeMajor.email });
  //     })
  //     .catch((error) => console.log("Error! " + error.message));
  // }

  //=================GET EMAIL BODY AND PASS TO SEND_EMAIL ==========================

  getEmailBody() {
    var student_info_title = "Request from: ";
    var student_info_name = this.state.first_name + " " + this.state.last_name;
    var student_info_emplid = this.state.emplid;
    var major_to_change_title = this.state.major_to_change_title;
    var major_to_change = this.state.major_to_change;

    var new_major_title = this.state.new_major_title;
    var new_major_text = this.state.new_major.label;

    var new_campus_title = this.state.new_campus_title;
    var new_campus_text = this.state.new_campus.label;

    if (this.state.new_subplan && this.state.new_campus) {
      var new_subplan_title = this.state.new_subplan_title;
      var new_subplan_text = this.state.new_subplan.label;
    }

    var contact_phone = this.state.contact_phone;
    var contact_phone_title = this.state.contact_phone_title;
    var double_check_title = this.state.double_check_title;
    var double_check = this.state.double_check;
    var additional_info = this.state.additional_info;
    var additional_info_title = this.state.additional_info_title;
    var requirements_check_title = this.state.requirements_check_title;
    var requirements_check = this.state.requirements_check;
    var major_change_source = this.state.major_change_source.label;
    var major_change_source_title = this.state.major_change_source_title;
    var why_title = this.state.why_title;
    var why = this.state.why.label;

    var bodyText =
      student_info_title +
      "\n" +
      student_info_name +
      "(" +
      student_info_emplid +
      ")" +
      "\n\n";

    bodyText += major_to_change_title + "\n" + major_to_change + "\n\n";

    bodyText += new_major_title + "\n" + new_major_text + "\n\n";

    if (this.state.new_subplan && this.state.new_campus) {
      bodyText += new_subplan_title + "\n" + new_subplan_text + "\n\n";
    }

    bodyText += new_campus_title + "\n" + new_campus_text + "\n\n";

    bodyText += requirements_check_title + "\n" + requirements_check + "\n\n";

    bodyText += double_check_title + "\n" + double_check + "\n\n";

    bodyText += contact_phone_title + "\n" + contact_phone + "\n\n";

    bodyText += major_change_source_title + "\n" + major_change_source + "\n\n";

    bodyText += why_title + "\n" + why + "\n\n";

    bodyText += additional_info_title + "\n" + additional_info + "\n\n";

    return bodyText;
  }

  getEmailBodyJson() {
    var student_info_emplid = this.state.emplid;

    var major_to_change = this.state.major_to_change;

    var new_major_text = this.state.new_major.label;
    var new_campus_text = this.state.new_campus.label;

    if (this.state.new_subplan && this.state.new_campus) {
      var new_subplan_text = this.state.new_subplan.label;
    }

    var contact_phone = this.state.contact_phone;

    var double_check = this.state.double_check;

    //escape 5 special characters
    var additional_info = this.state.additional_info.replace(/["<>&']/g, " ");

    var requirements_check = this.state.requirements_check;
    var major_change_source = this.state.major_change_source.label;

    var why = this.state.why.label;

    var json_arr = {};
    json_arr["emplid"] = student_info_emplid;
    json_arr["first_name"] = this.state.first_name;
    json_arr["last_name"] = this.state.last_name;
    json_arr["major_to_change"] = major_to_change;
    json_arr["new_major"] = new_major_text;

    if (this.state.new_subplan && this.state.new_campus) {
      json_arr["new_subplan"] = new_subplan_text;
    }

    json_arr["new_campus"] = new_campus_text;
    json_arr["requirements_check"] = requirements_check;
    json_arr["double_check"] = double_check;
    json_arr["contact_phone"] = contact_phone;
    json_arr["source"] = major_change_source;
    json_arr["why"] = why;
    json_arr["additional_info"] = additional_info;
    json_arr["form_type"] = "major";

    var bodyTextJson = JSON.stringify(json_arr);
    return bodyTextJson;
  }

  //=========SEND EMAIL TO SALESFORCE AFTER SUBMIT==============

  async sendEmail() {
    var bodyText = this.getEmailBody();

    var bodyTextJson = this.getEmailBodyJson();

    // var toEmail = "";
    // var fromEmail = "guirongg@asu.edu";
    var plancode = this.state.new_major.value;
    var emplid = this.state.emplid;
    var subplan = "";
    if (this.state.new_subplan && this.state.new_campus)
      subplan = this.state.new_subplan.value;
    var campus = this.state.new_campus.value;

    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "text/html",
      },
      body: JSON.stringify({
        authorizationToken: sessionStorage.getItem(serviceauth.SS_JWT_TOKEN),
        api: "salesforce-case",
        emplid: emplid,
        plancode: plancode,
        subplan: subplan,
        campus: campus,
        subject: "Change of Major Request Form Submission",
        bodyText: bodyText,
        bodyTextJson: bodyTextJson,
      }),
    };

    await fetch(this.endpoint, requestOptions)
      .then((response) => response.json())
      .then((data) => {
        if (data === "401") {
          sessionStorage.setItem("progress", "major");
          sessionStorage.setItem("storedAsurite", this.state.asurite);
          sessionStorage.setItem("emailBody", bodyText);
          sessionStorage.setItem("emailBodyJson", bodyTextJson);
          sessionStorage.setItem("plancode", plancode);
          sessionStorage.setItem("subplan", subplan);
          sessionStorage.setItem("campus", campus);
          sessionStorage.setItem("emplid", emplid);
          sessionStorage.setItem(
            "storedToken",
            sessionStorage.getItem(serviceauth.SS_JWT_TOKEN)
          );

          //alert("Your session timed out. Please press \"Reload\" to sign in and submit again.");

          window.location.href = "/";
        }

        console.log("submit email:" + JSON.stringify(data.statusCode));
        if (data.statusCode === 201 || data.statusCode === 200) {
          this.clearProgressSessionStorage();
          window.location.href = "/confirmation";
        }
      })
      .catch((error) => console.log("Error! " + error.message));
  }

  //=========RE SEND EMAIL TO SALESFORCE WHEN SESSION EXPIRED ==============

  async resendEmail() {
    //alert("call resend email");

    var bodyText = sessionStorage.getItem("emailBody");
    var bodyTextJson = sessionStorage.getItem("emailBodyJson");
    var plancode = sessionStorage.getItem("plancode");
    var subplan = sessionStorage.getItem("subplan");
    var campus = sessionStorage.getItem("campus");

    var token = sessionStorage.getItem(serviceauth.SS_JWT_TOKEN);
    var storedToken = sessionStorage.getItem("storedToken");

    var storedAsurite = sessionStorage.getItem("storedAsurite");
    var emplid = sessionStorage.getItem("emplid");

    //alert("token=" + token);

    if (token != null && token !== storedToken) {
      if (
        storedAsurite === this.state.asurite ||
        this.state.admin.includes(
          sessionStorage.getItem(serviceauth.SS_ASURITE)
        )
      ) {
        // alert("resend email");
        const requestOptions = {
          method: "POST",
          headers: {
            "Content-Type": "text/html",
          },
          body: JSON.stringify({
            authorizationToken: token,
            api: "salesforce-case",
            emplid: emplid,
            plancode: plancode,
            subplan: subplan,
            campus: campus,
            subject: "Change of Major Request Form Submission",
            bodyText: bodyText,
            bodyTextJson: bodyTextJson,
          }),
        };

        await fetch(this.endpoint, requestOptions)
          .then((response) => response.json())
          .then((data) => {
            this.clearProgressSessionStorage();

            if (data.statusCode === 201 || data.statusCode === 200) {
              window.location.href = "/confirmation";
            }
          })
          .catch((error) => console.log("Error! " + error.message));
      }
    }
  }

  // darsAudit() {
  //   const requestOptions = {
  //     method: "POST",
  //     headers: { "Content-Type": "application/json" },
  //     body: JSON.stringify({
  //       authorizationToken: sessionStorage.getItem(serviceauth.SS_JWT_TOKEN),
  //       api: "dars-audit",
  //       emplid: this.state.emplid,
  //       dprog: "ES BME BSE",
  //       yterm: "20207",
  //     }),
  //   };

  //   fetch(this.endpoint, requestOptions)
  //     .then((response) => response.json())
  //     .then((data) => {
  //       console.log("data=" + data);
  //     })
  //     .catch((error) => console.log("Error! " + error.message));
  // }

  validate = () => {
    let new_major_error;
    let new_campus_error;
    let new_subplan_error;
    let contact_phone_error;
    let major_change_source_error;
    let why_error;
    let error_message = "";

    if (!this.state.new_major) {
      new_major_error = "error";
      error_message +=
        "<li>" + this.state.new_major_title + " field is required</li>";
    }

    if (this.state.subplans && this.state.new_campus) {
      if (!this.state.new_subplan) {
        new_subplan_error = "error";
        error_message +=
          "<li>" + this.state.new_subplan_title + " field is required</li>";
      }
    }
    if (!this.state.new_campus || !this.state.new_campus.value) {
      new_campus_error = "error";
      error_message +=
        "<li>" + this.state.new_campus_title + " field is required</li>";
    }

    if (!this.state.new_major_wp_carey) {
      if (this.state.contact_phone === "") {
        contact_phone_error = "error";
        error_message +=
          "<li>" + this.state.contact_phone_title + " field is required</li>";
      }

      if (!this.state.major_change_source) {
        major_change_source_error = "error";
        error_message +=
          "<li>" +
          this.state.major_change_source_title +
          " field is required</li>";
      }

      if (!this.state.why) {
        why_error = "error";
        error_message +=
          "<li>" + this.state.why_title + " field is required</li>";
      }
    }

    if (
      new_major_error ||
      new_campus_error ||
      new_subplan_error ||
      contact_phone_error ||
      major_change_source_error ||
      why_error
    ) {
      error_message = "<div><ul>" + error_message + "</ul></div>";
      this.setState({ error_message });
      this.setState({
        new_major_error,
        new_subplan_error,
        new_campus_error,
        contact_phone_error,
        major_change_source_error,
        why_error,
      });

      return false;
    }
    return true;
  };

  handleSubmit = (event) => {
    event.preventDefault();

    const isValid = this.validate();
    if (isValid) {
      $(".submitWait").show();
      this.sendEmail();
      //this.darsAudit();
    }
  };

  //===switch View-As ===

  handleSwitch() {
    sessionStorage.removeItem(serviceauth.SS_VIEW_AS);
    window.location.href = "/";
  }

  handleSubmitWPCarey = (event) => {
    event.preventDefault();

    const isValid = this.validate();
    if (isValid) {
      let new_major = this.state.new_major.value;
      let new_campus = this.state.new_campus.value;
      let new_subplan = this.state.new_subplan;

      var redirectUrl = this.wpcareyUrl + "?source=ASU&";
      redirectUrl += "acadPlan=" + new_major + "&campus=" + new_campus;
      if (new_subplan) {
        redirectUrl += "&subplan=" + new_subplan;
      }
      window.location.href = redirectUrl;
    }
  };

  handleChangeSelect(name, value) {
    //var value = event.target.value;
    this.setState({ [name]: value });

    if (name === "new_major") {
      var hits = this.state.hits;
      var acadPlan = value.value;
      this.getCollegeLocationsByAcadPlan(hits, acadPlan);
    }
    if (name === "new_campus") {
      console.log("new campus: " + value.value);
      var mmSubplanCampus = "ALL";
      if (value.value === "ONLNE") {
        mmSubplanCampus = "ONLINE";
      }
      hits = this.state.hits;
      acadPlan = this.state.new_major.value;
      this.getSubplanByAcadPlanCampus(hits, acadPlan, mmSubplanCampus);
    }
  }

  handleChange(event, checkedItems) {
    const type = event.target.type;
    var name = event.target.name;

    // console.log("name=" + name + ",value=" + event.target.value);

    if (type === "radio" || type.indexOf("select") >= 0) {
      this.setState({ [name]: event.target.value }, () => {});
    } else if (type === "checkbox") {
      var val = [];
      if (checkedItems !== null) {
        //multiple select
        checkedItems.forEach((value, key) => {
          if (value) {
            val.push(key);
          }
        });
      } else {
        //regular checkbox
        val = event.target.checked;
      }

      this.setState(
        {
          [name]: val,
        },
        () => {
          console.log("after state update");
          // this.pushToHistory();
        }
      );
    } else {
      // console.log("hello" + type + event.target.value);
      this.setState({ [name]: event.target.value });
    }
  }

  componentDidMount() {
    var progress = sessionStorage.getItem("progress");
    var emailBody = sessionStorage.getItem("emailBody");
    // console.log("progres=" + progress);
    // console.log("emailboyd=" + emailBody);
    // console.log("token=" + sessionStorage.getItem(serviceauth.SS_JWT_TOKEN));

    if (progress === "major" && emailBody !== null) {
      // alert("resendEmail");

      $(".loadWait").hide();
      $(".submitWait").hide();
      $(".resubmitWait").show();
      this.resendEmail();
    } else if (
      progress !== "minors" &&
      progress !== "certs" &&
      progress !== "concurrent"
    ) {
      this.clearProgressSessionStorage();
      this.getNextTerm();
      // get plancode from url if there is plancode
      var values = queryString.parse(this.props.location.search);
      var acadPlan = values.acadplan;
      this.getStudentMajor(acadPlan); // get student current majors and elasticsearch major list

      if (acadPlan) this.setState({ new_major: acadPlan });
    }
  }

  render() {
    return (
      <Container fluid={true} className="p-0">
        <Header />
        <Menu />
        <Helmet>
          <title>Change of Major Request Form</title>
        </Helmet>

        {this.state.emplid ? (
          <div>
            <Container>
              {this.state.error_message ? (
                <div className="error_message">
                  {ReactHtmlParser(this.state.error_message)}
                </div>
              ) : null}

              <div className="pageTitle">Change of Major Request Form</div>
            </Container>
            <br />
            <br />

            <Container className="changeMajorForm">
              <h2>
                {this.state.first_name} {this.state.last_name}
              </h2>
              {this.state.studentMajors.map((plan) => (
                <div>
                  {plan.transcriptDescription}, {plan.degree}
                </div>
              ))}
              <br />
              <Form.Group>
                <Form.Label>
                  {this.state.major_to_change_title}
                  <span className="red"> *</span>
                </Form.Label>

                {this.state.studentMajors.map((plan, index) =>
                  plan.planType === "MAJ" || plan.planType === "PRE" ? (
                    <Form.Check
                      type="radio"
                      id={"major_to_change_" + plan.planCode}
                      name="major_to_change"
                      checked={
                        this.state.major_to_change ===
                        plan.transcriptDescription + ", " + plan.degree
                      }
                      onChange={this.handleChange}
                      value={plan.transcriptDescription + ", " + plan.degree}
                      label={plan.transcriptDescription + ", " + plan.degree}
                    />
                  ) : null
                )}
              </Form.Group>

              <div id="form_new_major" className={this.state.new_major_error}>
                <label
                  for="new_major"
                  className={this.state.new_major_error + "_label form-label"}
                >
                  {this.state.new_major_title} <span className="red"> *</span>
                </label>

                {this.environment === "dev" ? (
                  <a
                    href="https://degrees-dev.apps.asu.edu/change"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {" "}
                    Still not sure? Search all majors
                  </a>
                ) : (
                  <a
                    href="https://degrees.apps.asu.edu/change"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {" "}
                    Still not sure? Search all majors
                  </a>
                )}

                <Select
                  id="new_major"
                  value={this.state.new_major}
                  defaultValue={this.state.new_major}
                  options={this.state.ds_major_list}
                  onChange={this.handleChangeSelect.bind(this, "new_major")}
                  className={this.state.new_major_error + "_select"}

                  //openMenuOnClick={false}
                />
              </div>
              <p></p>
              <div id="form_new_campus" className={this.state.new_campus_error}>
                <label
                  for="new_campus"
                  className={this.state.new_campus_error + "_label form-label"}
                >
                  {this.state.new_campus_title} <span className="red"> *</span>
                </label>
                {this.state.new_campus ? (
                  <Select
                    id="new_campus"
                    value={this.state.new_campus}
                    options={this.state.campus_list}
                    onChange={this.handleChangeSelect.bind(this, "new_campus")}
                    defaultValue={this.state.new_campus}
                    className={this.state.new_campus_error + "_select"}
                    isClearable={true}
                  />
                ) : (
                  <Select
                    id="new_campus"
                    value={this.state.new_campus}
                    options={this.state.campus_list}
                    onChange={this.handleChangeSelect.bind(this, "new_campus")}
                    className={this.state.new_campus_error + "_select"}
                    isClearable={true}
                  />
                )}
              </div>

              {this.state.subplans && this.state.new_campus ? (
                <div id="form_subplan" className={this.state.new_subplan_error}>
                  <p></p>
                  <label
                    for="new_subplan"
                    className={
                      this.state.new_subplan_error + "_label form-label"
                    }
                  >
                    {this.state.new_subplan_title}{" "}
                    <span className="red"> *</span>
                  </label>
                  <Select
                    id="new_subplan"
                    value={this.state.new_subplan}
                    options={this.state.subplans}
                    onChange={this.handleChangeSelect.bind(this, "new_subplan")}
                    className={this.state.new_subplan_error + "_select"}
                    isClearable={true}
                  />
                </div>
              ) : (
                <p></p>
              )}

              {this.state.new_major_wp_carey ? (
                <p></p>
              ) : (
                <div>
                  <p></p>
                  <p>
                    <label class="form-label">
                      Change of Major requirements:
                    </label>
                    <div>{ReactHtmlParser(this.state.changemajor_text)}</div>
                  </p>

                  <Form.Group>
                    <Form.Label>
                      {this.state.requirements_check_title}{" "}
                      <span className="red"> *</span>
                    </Form.Label>

                    <Form.Check
                      type="radio"
                      id="requirements_check_yes"
                      checked={this.state.requirements_check === "Yes"}
                      onChange={this.handleChange}
                      value="Yes"
                      name="requirements_check"
                      label="Yes"
                      className="circle"
                    />

                    <Form.Check
                      type="radio"
                      id="requirements_check_no"
                      name="requirements_check"
                      checked={this.state.requirements_check === "No"}
                      onChange={this.handleChange}
                      value="No"
                      label="No"
                      // onChange={this.handleChange}
                    />
                    <Form.Check
                      type="radio"
                      id="requirements_check_unknow"
                      name="requirements_check"
                      label="I don't know"
                      checked={this.state.requirements_check === "I don't know"}
                      onChange={this.handleChange}
                      value="I don't know"
                      // onChange={this.handleChange}
                    />
                  </Form.Group>
                  <Form.Group>
                    <Form.Label>
                      {this.state.double_check_title}{" "}
                      <span className="red"> *</span>
                    </Form.Label>

                    <Form.Check
                      type="radio"
                      id="double_check_sure"
                      checked={this.state.double_check === "I am sure"}
                      onChange={this.handleChange}
                      value="I am sure"
                      name="double_check"
                      label="I'm sure"
                    />
                    <Form.Check
                      type="radio"
                      id="double_check_unsure"
                      name="double_check"
                      value="I want to speak to someone first"
                      checked={
                        this.state.double_check ===
                        "I want to speak to someone first"
                      }
                      onChange={this.handleChange}
                      label="I want to speak to someone first"
                    />
                  </Form.Group>
                  <Form.Group
                    controlId="contact_phone"
                    className={this.state.contact_phone_error}
                  >
                    <Form.Label
                      className={this.state.contact_phone_error + "_label"}
                    >
                      {this.state.contact_phone_title}{" "}
                      <span className="red"> *</span>
                    </Form.Label>
                    <Form.Control
                      type="text"
                      onChange={this.handleChange}
                      value={this.state.contact_phone}
                      name="contact_phone"
                      className={this.state.new_campus_error + "_select"}
                    />
                  </Form.Group>

                  <div
                    id="form_major_change_source"
                    className={this.state.major_change_source_error}
                  >
                    <label
                      for="major_change_source"
                      className={
                        this.state.major_change_source_error +
                        "_label form-label"
                      }
                    >
                      {this.state.major_change_source_title}{" "}
                      <span className="red"> *</span>
                    </label>
                    <Select
                      id="major_change_source"
                      value={this.state.major_change_source}
                      options={StaticData.major_change_sourceList}
                      className={
                        this.state.major_change_source_error + "_select"
                      }
                      isClearable={true}
                      onChange={this.handleChangeSelect.bind(
                        this,
                        "major_change_source"
                      )}
                    />
                  </div>
                  <p></p>
                  <div id="form_why" className={this.state.why_error}>
                    <label
                      for="why"
                      className={this.state.why_error + "_label form-label"}
                    >
                      {this.state.why_title} <span className="red"> *</span>
                    </label>
                    <Select
                      id="why"
                      value={this.state.why}
                      options={StaticData.whyList}
                      onChange={this.handleChangeSelect.bind(this, "why")}
                      className={this.state.why_error + "_select"}
                      isClearable={true}
                    />
                  </div>
                  <p></p>
                  <label class="form-label">
                    How do the classes I've already taken apply to this new
                    major?
                  </label>
                  <br />
                  <Button
                    className="btn btn-secondary"
                    href="https://webapp4.asu.edu/uachieve"
                    target="_blank"
                  >
                    Run a What If audit
                  </Button>
                  <p></p>
                  <div id="form_additional_info">
                    <label
                      for="additional_info"
                      className={"_label form-label"}
                    >
                      {this.state.additional_info_title}
                    </label>
                    <textarea
                      className="form-control"
                      id="additional_info"
                      rows="5"
                      onChange={this.handleChange}
                      value={this.state.additional_info}
                      name="additional_info"
                    />
                  </div>
                </div>
              )}
              <br />
              <br />
              <div id="changeMajorDisclaimer">
                <p>
                  Changing your major and/or campus may have an impact on your
                  tuition and fees. Some programs charge differential or program
                  specific tuition, which is in addition to the base tuition
                  charged each semester you enroll.
                </p>
                <p>
                  Additionally, if you are a sponsored student, receive tuition
                  benefits from a non-ASU entity or accept certain federal
                  funds, changing your major may impact your college costs.
                </p>
                <p>
                  Please consult with the financial aid office for details and
                  visit this website:
                  <br />
                  <a
                    href="https://students.asu.edu/tuition"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    https://students.asu.edu/tuition.
                  </a>
                </p>
              </div>
              {this.state.new_major_wp_carey ? (
                <Form
                  onSubmit={this.handleSubmitWPCarey}
                  className="searchDegreeSearchBtn"
                >
                  <Button className="btn-asu" type="submit">
                    Proceed to W.P.Carey's Advising SOS website
                  </Button>
                </Form>
              ) : (
                <Form
                  onSubmit={this.handleSubmit}
                  className="searchDegreeSearchBtn"
                >
                  <Button className="btn-asu" type="submit">
                    Submit Request for Change of Major
                  </Button>
                </Form>
              )}

              <br />
              <br />
              <br />
            </Container>

            {sessionStorage.getItem(serviceauth.SS_VIEW_AS) ? (
              <Container>
                <div className="error_message">
                  You are viewing as student{" "}
                  {sessionStorage.getItem(serviceauth.SS_VIEW_AS)} {". "}
                  <button onClick={this.handleSwitch}> Click </button> to change
                  back to {sessionStorage.getItem(serviceauth.SS_NAME)}
                </div>
              </Container>
            ) : null}

            <Button className="btn wait submitWait nodisplay">
              <FontAwesomeIcon icon={faSpinner} spin /> Submitting Request ...
            </Button>

            <Footer />
          </div>
        ) : null}

        <Button className="btn wait loadWait">
          <FontAwesomeIcon icon={faSpinner} spin /> Loading ...
        </Button>

        <Button className="btn wait resubmitWait nodisplay">
          <FontAwesomeIcon icon={faSpinner} spin /> Submitting Request... Do Not
          Close Browser.
        </Button>
      </Container>
    );
  }
}

export default RequestForm;

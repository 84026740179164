import React, { Component } from "react";
import "./css/App.css";
import Select from "react-select";
import Container from "react-bootstrap/Container";
import StaticData from "./StaticData";
import Menu from "./components/Menu";
import Header from "./components/Header";
import Footer from "./components/Footer";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import ReactHtmlParser from "react-html-parser";
import queryString from "query-string";
import $ from "jquery";
import serviceauth from "./auth/serviceauth";
import { properties } from "./properties.js";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Helmet } from "react-helmet";

class RequestForm extends Component {
  changemajor_text =
    "Please choose a Certificate above to see the Certificate requirements.";
  subject = "Add Certificate Request Form Submission";

  endpoint = properties.endpoint;
  environment = properties.environment;

  initialState = {
    // new_subplan: null,
    new_campus: null,
    contact_phone: "",
    major_change_source: null,
    why: null,
    additional_info: "",
  };

  constructor(props) {
    super(props);

    this.state = {
      asurite:
        sessionStorage.getItem(serviceauth.SS_VIEW_AS) != null
          ? sessionStorage.getItem(serviceauth.SS_VIEW_AS)
          : sessionStorage.getItem(serviceauth.SS_ASURITE),
      admin: this.environment !== "prod" ? StaticData.adminList : [],
      term: "",
      emplid: "",
      first_name: "",
      last_name: "",
      student_type: "Ground",

      new_major: null,

      new_major_title: "Which certificate would you like to add?",

      new_campus: null,
      new_campus_title: "Which campus do you want to attend?",

      requirements_check: "Yes",
      requirements_check_title: "Do you currently meet these requirements?",

      double_check: "I am sure",
      double_check_title:
        "How sure are you that you would like to add this certificate?",

      contact_phone: "",
      contact_phone_title: "At which phone number can you be reached?",

      major_change_source: null,
      major_change_source_title: "How did you hear about this new certificate?",

      why: null,
      why_title: "Why do you want to add this certificate?",

      additional_info: "",
      additional_info_title:
        "Is there any other additional information that you would like us to know?",

      confirmation: "",

      studentMajors: [], // student current majors from Mulesoft API
      student_first_major_plancode: "", //student's major plancode (first major if has multiple)
      student_first_major_subplan_code: "",
      //   student_first_major_wp_carey:"",
      //   all_undergrad_majors: [], // all undergrad majors
      hits: [], // all minors list from Degree search elasticsearch
      ds_major_list: null, // major dropdown
      campus_list: [{ value: null, label: "No campuses to display" }], // campus dropdown when a major is choosen

      changemajor_text: this.changemajor_text,

      new_major_error: "",
      new_campus_error: "",
      requirements_check_error: "",
      double_check_error: "",
      contact_phone_error: "",
      major_change_source_error: "",
      why_error: "",
      error_message: "",
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleChangeSelect = this.handleChangeSelect.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  clearProgressSessionStorage() {
    sessionStorage.removeItem("progress");
    sessionStorage.removeItem("storedAsurite");
    sessionStorage.removeItem("storedEmplid");
    sessionStorage.removeItem("emailBody");
    sessionStorage.removeItem("emailBodyJson");
    sessionStorage.removeItem("psXml");
    sessionStorage.removeItem("plancode");
    sessionStorage.removeItem("subplan");
    sessionStorage.removeItem("campus");
    sessionStorage.removeItem("storedToken");
  }
  getNextTerm() {
    var date = new Date();
    var month = date.getMonth();
    var year = date.getFullYear().toString().substr(2, 2);
    var nextYear = (date.getFullYear() + 1).toString().substr(2, 2);

    if (month >= 0 && month <= 6) {
      this.setState({ term: "2" + year + "7" });
    } else this.setState({ term: "2" + nextYear + "1" });
  }

  //===============GET STUDENT DATA FROM MULESOFT API ========

  async getStudentMajor(acadPlan) {
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        authorizationToken: sessionStorage.getItem(serviceauth.SS_JWT_TOKEN),
        api: "student-major",
        asurite: this.state.asurite,
      }),
    };
    console.log("get studenmajor for ausrite: " + this.state.asurite);

    if (this.state.asurite) {
      await fetch(this.endpoint, requestOptions)
        .then((response) => response.json())
        .then((data) => {
          console.log("student:" + JSON.stringify(data.studentMajor));

          if (data.studentMajor) {
            $(".loadWait").hide();
            $(".submitWait").hide();
            $(".resubmitWait").hide();

            var show_form = false;

            data.studentMajor.forEach((studentMajor) => {
              this.setState({ emplid: studentMajor.emplid });
              this.setState({ first_name: studentMajor.firstName });
              this.setState({ last_name: studentMajor.lastName });
              var careers = studentMajor.careers;

              if (careers) {
                careers.forEach((careerr) => {
                  if (careerr.career && careerr.career === "UGRD") {
                    var career = careerr.career;
                    var plans = careerr.plans;
                    this.setState({ career: career });
                    this.setState({ studentMajors: plans });
                    if (plans) {
                      plans.forEach((plan) => {
                        if (
                          plan.degree !== "AA" &&
                          plan.degree !== "AS" && //block Military studies student and Associated in Science students from access any form.
                          (plan.planType === "MAJ" ||
                            plan.planType === "PRE") &&
                          plan.planCode !== "BALGMARAS" &&
                          plan.planCode !== "LSORGLAA" &&
                          plan.planCode !== "LSMILSTAA" &&
                          plan.planCode !== "PPEMEAS" &&
                          plan.planCode !== "PPEMECERT"
                        ) {
                          // this.setState({student_first_major_plancode:plan.planCode});

                          // var subplans=plan.subplans;
                          // if(subplans){
                          //     subplans.forEach(subplan => {
                          //         this.setState({student_first_major_subplan_code:subplan.subplan});
                          //     });
                          // }
                          var student_type = "Ground";
                          if (plan.campus === "ONLNE") {
                            student_type = "Online";
                            this.setState({
                              student_type: "Online",
                            });
                          }
                          this.getCertsElasticSearch(student_type, acadPlan);
                          show_form = true;
                        }
                      });
                    }
                  }
                });
              }
            });
            if (show_form === false) {
              window.location.href = "/notice";
            }
          } else {
            if (JSON.stringify(data).includes("enrolled")) {
              window.location.href = "/ineligibleOther";
            } else window.location.href = "/notice";
          }
        })
        .catch((error) => console.log("Error student major! " + error.message));
    }
  }

  //   async getAllUndergradMajorsFromElasticSearch(first_major_plancode) {

  //     console.log("environment = " + this.environment);
  //     console.log("next term: " + this.state.term);
  //     const requestOptions = {
  //       method: "POST",
  //       headers: {
  //         "Content-Type": "application/json",
  //       },
  //       body: JSON.stringify({
  //         api: "elasticsearch",
  //         elasticIndex: "program-undergrad-false",
  //         authorizationToken: sessionStorage.getItem(serviceauth.SS_JWT_TOKEN),
  //         outputParams: [
  //           "DEGREE",
  //           "ACADPLAN",
  //           "CAMPUSDESCR",
  //           "MAJORDESCRIPTION",
  //           "CHANGEMAJOR",
  //           "MAJORMAPSUBPLANS",
  //           "COLLEGEURLANDDESCR",
  //           // "DARSAUDIT",
  //         ],
  //         matchQuery: this.state.term,
  //         matchFields: ["APPLYDEADLINES.KEY"],
  //       }),
  //     };

  //     await fetch(this.endpoint, requestOptions)
  //       .then((response) => response.json())
  //       .then((data) => {
  //         console.log("data=" + JSON.stringify(data));

  //         data.sort(function (a, b) {
  //           a = a._source.MAJORDESCRIPTION.toLowerCase();
  //           b = b._source.MAJORDESCRIPTION.toLowerCase();

  //           return a < b ? -1 : a > b ? 1 : 0;
  //         });

  //         this.setState({all_undergrad_majors: data });
  //         console.log(data);

  //         this.isMajorWpCarey(data,first_major_plancode);

  //       })
  //       .catch((error) => console.log("Error elastic! " + error.message));
  //   }

  // =============== GET PLAN DATA FROM ELASTICSEARCH API =========
  async getCertsElasticSearch(student_type, acadPlan) {
    console.log("environment = " + this.environment);
    console.log("next term: " + this.state.term);
    console.log("acadplan: " + acadPlan);
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        api: "elasticsearch",
        elasticIndex: "program-undergrad-true",
        authorizationToken: sessionStorage.getItem(serviceauth.SS_JWT_TOKEN),
        outputParams: [
          "DEGREE",
          "ACADPLAN",
          "CAMPUSDESCR",
          "MAJORDESCRIPTION",
          "CHANGEMAJOR",
          "MAJORMAPSUBPLANS",
          "COLLEGEURLANDDESCR",
          "MAJORDESCRLONG",
          "CAMPUSLIST",
          "REQDESCR",
          // "DARSAUDIT",
        ],
        matchQuery: "Certificate",
        matchFields: ["DEGREE"],
      }),
    };

    await fetch(this.endpoint, requestOptions)
      .then((response) => response.json())
      .then((data) => {
        console.log("data=" + JSON.stringify(data));

        data.sort(function (a, b) {
          a = a._source.MAJORDESCRIPTION.toLowerCase();
          b = b._source.MAJORDESCRIPTION.toLowerCase();

          return a < b ? -1 : a > b ? 1 : 0;
        });

        this.setState({ hits: data });
        var majorList = [];
        data.forEach((hit) => {
          var major = {
            value: hit._source.ACADPLAN,
            label:
              hit._source.MAJORDESCRIPTION +
              ", " +
              hit._source.DEGREE +
              " [" +
              hit._source.ACADPLAN +
              "]",
          };

          console.log("campusList= " + hit._source.CAMPUSLIST);
          if (major.value !== "BALGMACERT" && major.value !== "LSORGLCERT") {
            if (student_type === "Online") {
              if (hit._source.CAMPUSLIST.indexOf("Online") !== -1) {
                majorList.push(major);
                if (hit._source.ACADPLAN === acadPlan) {
                  this.setState({ new_major: major });
                }
              }
            } else if (student_type === "Ground") {
              if (hit._source.CAMPUSLIST.trim() !== "Online") {
                majorList.push(major);
                if (hit._source.ACADPLAN === acadPlan) {
                  this.setState({ new_major: major });
                }
              }
            }
          }
        });
        this.setState({ ds_major_list: majorList });
        if (acadPlan) {
          this.getLocationsByAcadPlan(data, acadPlan, student_type);
          sessionStorage.removeItem("queryStringAcadplan");
        }
      })
      .catch((error) => console.log("Error elastic! " + error.message));
  }

  //===========GET LOCATION BY PLAN CODE FROM PLAN DATA (FROM ELASTICSEARCH)========================

  getLocationsByAcadPlan(hits, acadPlan, student_type) {
    // console.log("getLocation plan=" + acadPlan);
    hits.forEach((hit) => {
      if (hit._source.ACADPLAN === acadPlan) {
        var campus_list = [];

        if (hit._source.CAMPUSDESCR) {
          hit._source.CAMPUSDESCR.forEach((campus) => {
            if (
              (student_type === "Online" && campus.VALUE === "Online") ||
              (student_type === "Ground" && campus.VALUE !== "Online")
            ) {
              var campu = { value: campus.KEY, label: campus.VALUE };
              campus_list.push(campu);
            }
          });

          this.setState({ campus_list });
        } else {
          this.setState({ campus_list: null });
        }

        // this.setState({ new_major_college: hit._source.COLLEGEURLANDDESCR });

        if (hit._source.CHANGEMAJOR) {
          this.setState({ changemajor_text: hit._source.REQDESCR });
        } else {
          this.setState({ changemajor_text: this.changemajor_text });
        }

        this.setState(this.initialState);

        if (campus_list.length === 1) {
          this.setState({ new_campus: campus_list[0] });
        }
      }
    });
  }

  //=================GET EMAIL BODY AND PASS TO SEND_EMAIL ==========================

  getEmailBody() {
    // var notes = "FOR ADVISOR/ADMIN USER ONLY\nPlease navigate to https://go.psdev.asu.edu/TST/certrequestapproval to process the student’s certificate request.";
    // var notes = "FOR ADVISOR/ADMIN USER ONLY\nPost a comment in the Case Feed to indicate the student's Requirement term for their added certificate. (Ex: \"Requirement Term: Spring 2022\")";

    var student_info_title = "Request from: ";
    var student_info_name = this.state.first_name + " " + this.state.last_name;
    var student_info_emplid = this.state.emplid;
    // var major_to_change_title = this.state.major_to_change_title;
    // var major_to_change = this.state.major_to_change;

    var new_major_title = this.state.new_major_title;
    var new_major_text = this.state.new_major.label;

    var new_campus_title = this.state.new_campus_title;
    var new_campus_text = this.state.new_campus.label;

    var contact_phone = this.state.contact_phone;
    var contact_phone_title = this.state.contact_phone_title;
    var additional_info = this.state.additional_info;
    var additional_info_title = this.state.additional_info_title;
    var double_check_title = this.state.double_check_title;
    var double_check = this.state.double_check;
    var requirements_check_title = this.state.requirements_check_title;
    var requirements_check = this.state.requirements_check;
    var major_change_source = this.state.major_change_source.label;
    var major_change_source_title = this.state.major_change_source_title;
    var why_title = this.state.why_title;
    var why = this.state.why.label;

    var notes = "Information for student:\n";
    notes +=
      "Your request to add a certificate has been received and will be processed typically within 1-3 business days. You will be contacted by email and/or phone if there are any questions regarding your request. \n\n";
    notes += "ASU ID: " + student_info_emplid + "\n";
    notes += "Name: " + student_info_name + "\n";
    notes += "Student Certificate Plan: " + new_major_text + "\n\n";

    notes += "Information for advisor:\n";
    notes +=
      "Details: https://www.asu.edu/go/cs/bo/?dest=/EMPLOYEE/CAMP/c/ASU_STUDENT_RECORDS.ASU_SR_SRCHPLN_FL.GBL \n";
    notes +=
      "Reminder: After reviewing the certificate request, please update the PeopleSoft Certificate Request page to 'Approve' or 'Deny' and then notify the student.\n";

    var bodyText =
      "\n" +
      notes +
      "\n\n" +
      student_info_title +
      "\n" +
      student_info_name +
      "(" +
      student_info_emplid +
      ")" +
      "\n\n";

    bodyText += new_major_title + "\n" + new_major_text + "\n\n";

    bodyText += new_campus_title + "\n" + new_campus_text + "\n\n";

    bodyText += requirements_check_title + "\n" + requirements_check + "\n\n";

    bodyText += double_check_title + "\n" + double_check + "\n\n";

    bodyText += contact_phone_title + "\n" + contact_phone + "\n\n";

    bodyText += major_change_source_title + "\n" + major_change_source + "\n\n";

    bodyText += why_title + "\n" + why + "\n\n";

    bodyText += additional_info_title + "\n" + additional_info + "\n\n";

    return bodyText;
  }

  getEmailBodyJson() {
    var student_info_emplid = this.state.emplid;

    // var major_to_change = this.state.major_to_change;

    var new_major_text = this.state.new_major.label;
    var new_campus_text = this.state.new_campus.label;
    var contact_phone = this.state.contact_phone;
    var double_check = this.state.double_check;
    //escape five special characters
    var additional_info = this.state.additional_info.replace(/["<>&']/g, " ");
    var requirements_check = this.state.requirements_check;
    var major_change_source = this.state.major_change_source.label;
    var why = this.state.why.label;

    var json_arr = {};
    json_arr["emplid"] = student_info_emplid;
    json_arr["first_name"] = this.state.first_name;
    json_arr["last_name"] = this.state.last_name;
    json_arr["new_certificate"] = new_major_text;
    json_arr["new_campus"] = new_campus_text;
    json_arr["requirements_check"] = requirements_check;
    json_arr["double_check"] = double_check;
    json_arr["contact_phone"] = contact_phone;
    json_arr["source"] = major_change_source;
    json_arr["why"] = why;
    json_arr["additional_info"] = additional_info;
    json_arr["form_type"] = "certificate";

    var bodyTextJson = JSON.stringify(json_arr);
    return bodyTextJson;
  }

  //save form data to Peoplesoft through Mulsoft API
  getPsXml() {
    var student_info_emplid = this.state.emplid;
    var asurite = this.state.asurite;
    var plancode = this.state.new_major.value;
    var campus = this.state.new_campus.value;
    var contact_phone = this.state.contact_phone;

    var double_check = this.state.double_check;
    if (double_check.includes("sure")) {
      double_check = "Y";
    } else {
      double_check = "N";
    }

    var requirements_check = this.state.requirements_check;
    if (requirements_check === "Yes") {
      requirements_check = "Y";
    } else if (requirements_check === "No") {
      requirements_check = "N";
    } else {
      requirements_check = "I";
    }

    var major_change_source = this.state.major_change_source.label;
    var why = this.state.why.label;
    var additional_info = this.state.additional_info.replace(/["<>&']/g, " ");

    var psXml = "<emplid>" + student_info_emplid + "</emplid>";
    psXml += "<plan>" + plancode + "</plan>";

    psXml += "<campus>" + campus + "</campus>";

    psXml += "<reqcheck>" + requirements_check + "</reqcheck>";

    psXml += "<reqcheck2>" + double_check + "</reqcheck2>";

    psXml += "<phone>" + contact_phone + "</phone>";

    psXml += "<source>" + major_change_source + "</source>";

    psXml += "<txtresp>" + why + "</txtresp>";

    psXml += "<comment>" + additional_info + "</comment>";

    psXml += "<cb>" + asurite + "</cb>";

    return psXml;
  }

  // check if the form has been submitted and in pending. If a form is pending, it will not allow to resubmit.
  async checkSalesforceCaseStatus() {
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "text/html",
      },
      body: JSON.stringify({
        authorizationToken: sessionStorage.getItem(serviceauth.SS_JWT_TOKEN),
        api: "salesforce-case-check",
        psXml: this.getPsXml(),
        subject: this.subject,
      }),
    };

    await fetch(this.endpoint, requestOptions)
      .then((response) => response.json())
      .then((data) => {
        console.log("salesforce check return " + data);
        if (data === "P") {
          var plancode = this.state.new_major.value;
          window.location.href = "/pending?acadplan=" + plancode;
        } else {
          this.sendSalesforce();
        }
      })
      .catch((error) => console.log("Error! " + error.message));
  }

  //================== submit form to salesforce===============

  async sendSalesforce() {
    var bodyText = this.getEmailBody();
    var bodyTextJson = this.getEmailBodyJson();
    var psXml = this.getPsXml();
    var emplid = this.state.emplid;

    console.log("emplid=" + emplid);

    var plancode = this.state.new_major.value;
    var campus = this.state.new_campus.value;
    // var plancode = this.state.student_first_major_plancode;
    var subplanCode = null;

    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "text/html",
      },
      body: JSON.stringify({
        authorizationToken: sessionStorage.getItem(serviceauth.SS_JWT_TOKEN),
        api: "salesforce-case",
        plancode: plancode,
        subplan: subplanCode,
        campus: campus,
        subject: this.subject,
        bodyText: bodyText,
        emplid: emplid,
        bodyTextJson: bodyTextJson,
        psXml: psXml,
      }),
    };

    await fetch(this.endpoint, requestOptions)
      .then((response) => response.json())
      .then((data) => {
        console.log("salesforce return " + data);
        if (data === "401") {
          sessionStorage.setItem("progress", "certs");
          sessionStorage.setItem("storedAsurite", this.state.asurite);
          sessionStorage.setItem("storedEmplid", this.state.emplid);
          sessionStorage.setItem("emailBody", bodyText);
          sessionStorage.setItem("emailBodyJson", bodyTextJson);
          sessionStorage.setItem("psXml", psXml);
          sessionStorage.setItem("plancode", plancode);
          sessionStorage.setItem("subplan", subplanCode);
          sessionStorage.setItem("campus", campus);
          sessionStorage.setItem(
            "storedToken",
            sessionStorage.getItem(serviceauth.SS_JWT_TOKEN)
          );

          window.location.href = "/certs";
        }

        console.log("submit email:" + JSON.stringify(data.statusCode));
        if (data.statusCode === 201 || data.statusCode === 200) {
          this.clearProgressSessionStorage();
          window.location.href = "/confirmationCerts";
        }
      })
      .catch((error) => console.log("Error! " + error.message));
  }

  async resendSalesforce() {
    var bodyText = sessionStorage.getItem("emailBody");
    var bodyTextJson = sessionStorage.getItem("emailBodyJson");
    var psXml = sessionStorage.getItem("psXml");
    var plancode = sessionStorage.getItem("plancode");
    var subplan = sessionStorage.getItem("subplan");
    var campus = sessionStorage.getItem("campus");
    var emplid = sessionStorage.getItem("storedEmplid");

    var token = sessionStorage.getItem(serviceauth.SS_JWT_TOKEN);
    var storedToken = sessionStorage.getItem("storedToken");

    var storedAsurite = sessionStorage.getItem("storedAsurite");

    // alert("token=" + token);
    // alert("storedToken = " + storedToken);

    if (token != null && token !== storedToken) {
      if (
        storedAsurite === this.state.asurite ||
        this.state.admin.includes(
          sessionStorage.getItem(serviceauth.SS_ASURITE)
        )
      ) {
        // alert("resend email");
        const requestOptions = {
          method: "POST",
          headers: {
            "Content-Type": "text/html",
          },
          body: JSON.stringify({
            authorizationToken: token,
            api: "salesforce-case",
            plancode: plancode,
            subplan: subplan,
            campus: campus,
            subject: this.subject,
            bodyText: bodyText,
            emplid: emplid,
            bodyTextJson: bodyTextJson,
            psXml,
          }),
        };

        await fetch(this.endpoint, requestOptions)
          .then((response) => response.json())
          .then((data) => {
            this.clearProgressSessionStorage();

            if (data.statusCode === 201 || data.statusCode === 200) {
              window.location.href = "/confirmationCerts";
            }
          })
          .catch((error) => console.log("Error! " + error.message));
      }
    }
  }

  validate = () => {
    let new_major_error;
    let new_campus_error;
    let contact_phone_error;
    let major_change_source_error;
    let why_error;
    let error_message = "";

    if (!this.state.new_major) {
      new_major_error = "error";
      error_message +=
        "<li>" + this.state.new_major_title + " field is required</li>";
    }

    if (!this.state.new_campus || !this.state.new_campus.value) {
      new_campus_error = "error";
      error_message +=
        "<li>" + this.state.new_campus_title + " field is required</li>";
    }

    if (this.state.contact_phone === "") {
      contact_phone_error = "error";
      error_message +=
        "<li>" + this.state.contact_phone_title + " field is required</li>";
    }

    if (!this.state.major_change_source) {
      major_change_source_error = "error";
      error_message +=
        "<li>" +
        this.state.major_change_source_title +
        " field is required</li>";
    }

    if (!this.state.why) {
      why_error = "error";
      error_message +=
        "<li>" + this.state.why_title + " field is required</li>";
    }

    if (
      new_major_error ||
      new_campus_error ||
      // new_subplan_error ||
      contact_phone_error ||
      major_change_source_error ||
      why_error
    ) {
      error_message = "<div><ul>" + error_message + "</ul></div>";
      this.setState({ error_message });
      this.setState({
        new_major_error,
        // new_subplan_error,
        new_campus_error,
        contact_phone_error,
        major_change_source_error,
        why_error,
      });

      return false;
    }
    return true;
  };

  handleSubmit = (event) => {
    event.preventDefault();

    const isValid = this.validate();

    if (isValid) {
      $(".submitWait").show();
      this.checkSalesforceCaseStatus();
    }
  };

  //===switch View-As ===

  handleSwitch() {
    sessionStorage.removeItem(serviceauth.SS_VIEW_AS);
    window.location.href = "/certs";
  }

  handleChangeSelect(name, value) {
    //var value = event.target.value;
    this.setState({ [name]: value });

    if (name === "new_major") {
      var hits = this.state.hits;
      var acadPlan = value.value;
      this.getLocationsByAcadPlan(hits, acadPlan, this.state.student_type);
      // this.getSalesforceEmail(acadPlan);
    }
  }

  handleChange(event, checkedItems) {
    const type = event.target.type;
    var name = event.target.name;

    // console.log("name=" + name + ",value=" + event.target.value);

    if (type === "radio" || type.indexOf("select") >= 0) {
      this.setState({ [name]: event.target.value }, () => {});
    } else if (type === "checkbox") {
      var val = [];
      if (checkedItems !== null) {
        //multiple select
        checkedItems.forEach((value, key) => {
          if (value) {
            val.push(key);
          }
        });
      } else {
        //regular checkbox
        val = event.target.checked;
      }

      this.setState(
        {
          [name]: val,
        },
        () => {
          console.log("after state update");
          // this.pushToHistory();
        }
      );
    } else {
      // console.log("hello" + type + event.target.value);
      this.setState({ [name]: event.target.value });
    }
  }

  componentDidMount() {
    var progress = sessionStorage.getItem("progress");
    var emailBody = sessionStorage.getItem("emailBody");
    // console.log("progres=" + progress);
    // console.log("emailboyd=" + emailBody);
    // console.log("token=" + sessionStorage.getItem(serviceauth.SS_JWT_TOKEN));

    var values = queryString.parse(this.props.location.search);
    var acadPlan = values.acadplan;

    if (acadPlan) {
      sessionStorage.setItem("queryStringAcadplan", acadPlan);
    }

    var pageUrl = window.location.href;

    if (pageUrl.indexOf("/certs?page=certs") === -1) {
      window.location.href = "/certs?page=certs";
    }

    if (progress === "certs" && emailBody !== null) {
      // alert("resend salesforce for minors form");

      $(".loadWait").hide();
      $(".submitWait").hide();
      $(".resubmitWait").show();
      this.resendSalesforce();
    } else {
      this.clearProgressSessionStorage();

      this.getNextTerm();
      // get plancode from url if there is plancode
      var queryStringAcadplan = sessionStorage.getItem("queryStringAcadplan");
      console.log("querystring: " + queryStringAcadplan);
      this.getStudentMajor(queryStringAcadplan); // get student current majors and elasticsearch major list
    }
  }

  render() {
    return (
      <Container fluid={true} className="p-0">
        <Header />
        <Menu />
        <Helmet>
          <title>Add Certificate Request Form</title>
        </Helmet>

        {this.state.emplid ? (
          <div>
            <Container>
              {this.state.error_message ? (
                <div className="error_message">
                  {ReactHtmlParser(this.state.error_message)}
                </div>
              ) : null}

              <div className="pageTitle">Add Certificate Request Form</div>
            </Container>
            <br />
            <br />

            <Container className="changeMajorForm">
              <h2>
                {this.state.first_name} {this.state.last_name}
              </h2>
              {this.state.studentMajors.map((plan) => (
                <div>
                  {plan.transcriptDescription}, {plan.degree}
                </div>
              ))}
              <br />

              <div id="form_new_major" className={this.state.new_major_error}>
                <label
                  for="new_major"
                  className={this.state.new_major_error + "_label form-label"}
                >
                  {this.state.new_major_title} <span className="red"> * </span>
                </label>
                <a
                  href="https://degrees.apps.asu.edu/minors"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {" "}
                  Still not sure? Search all certificates
                </a>
                <Select
                  id="new_major"
                  value={this.state.new_major}
                  defaultValue={this.state.new_major}
                  options={this.state.ds_major_list}
                  onChange={this.handleChangeSelect.bind(this, "new_major")}
                  className={this.state.new_major_error + "_select"}

                  //openMenuOnClick={false}
                />
              </div>

              <br />
              <div id="form_new_campus" className={this.state.new_campus_error}>
                <label
                  for="new_campus"
                  className={this.state.new_campus_error + "_label form-label"}
                >
                  {this.state.new_campus_title} <span className="red"> *</span>
                </label>

                {this.state.new_campus ? (
                  <Select
                    id="new_campus"
                    value={this.state.new_campus}
                    options={this.state.campus_list}
                    onChange={this.handleChangeSelect.bind(this, "new_campus")}
                    defaultValue={this.state.new_campus}
                    className={this.state.new_campus_error + "_select"}
                    isClearable={true}
                  />
                ) : (
                  <Select
                    id="new_campus"
                    value={this.state.new_campus}
                    options={this.state.campus_list}
                    onChange={this.handleChangeSelect.bind(this, "new_campus")}
                    className={this.state.new_campus_error + "_select"}
                    isClearable={true}
                  />
                )}
              </div>
              <br />

              <div>
                <label class="form-label">Certificate requirements:</label>
                <div>{ReactHtmlParser(this.state.changemajor_text)}</div>
              </div>

              <div>
                <Form.Group>
                  <Form.Label>
                    {this.state.requirements_check_title}{" "}
                    <span className="red"> *</span>
                  </Form.Label>

                  <Form.Check
                    type="radio"
                    id="requirements_check_yes"
                    checked={this.state.requirements_check === "Yes"}
                    onChange={this.handleChange}
                    value="Yes"
                    name="requirements_check"
                    label="Yes"
                    className="circle"
                  />

                  <Form.Check
                    type="radio"
                    id="requirements_check_no"
                    name="requirements_check"
                    checked={this.state.requirements_check === "No"}
                    onChange={this.handleChange}
                    value="No"
                    label="No"
                    // onChange={this.handleChange}
                  />
                  <Form.Check
                    type="radio"
                    id="requirements_check_unknow"
                    name="requirements_check"
                    label="I don't know"
                    checked={this.state.requirements_check === "I don't know"}
                    onChange={this.handleChange}
                    value="I don't know"
                    // onChange={this.handleChange}
                  />
                </Form.Group>
                <Form.Group>
                  <Form.Label>
                    {this.state.double_check_title}{" "}
                    <span className="red"> *</span>
                  </Form.Label>

                  <Form.Check
                    type="radio"
                    id="double_check_sure"
                    checked={this.state.double_check === "I am sure"}
                    onChange={this.handleChange}
                    value="I am sure"
                    name="double_check"
                    label="I'm sure"
                  />
                  <Form.Check
                    type="radio"
                    id="double_check_unsure"
                    name="double_check"
                    value="I want to speak to someone first"
                    checked={
                      this.state.double_check ===
                      "I want to speak to someone first"
                    }
                    onChange={this.handleChange}
                    label="I want to speak to someone first"
                  />
                </Form.Group>
                <Form.Group
                  controlId="contact_phone"
                  className={this.state.contact_phone_error}
                >
                  <Form.Label
                    className={this.state.contact_phone_error + "_label"}
                  >
                    {this.state.contact_phone_title}{" "}
                    <span className="red"> *</span>
                  </Form.Label>
                  <Form.Control
                    type="text"
                    onChange={this.handleChange}
                    value={this.state.contact_phone}
                    name="contact_phone"
                    className={this.state.new_campus_error + "_select"}
                  />
                </Form.Group>

                <div
                  id="form_major_change_source"
                  className={this.state.major_change_source_error}
                >
                  <label
                    for="major_change_source"
                    className={
                      this.state.major_change_source_error + "_label form-label"
                    }
                  >
                    {this.state.major_change_source_title}{" "}
                    <span className="red"> *</span>
                  </label>
                  <Select
                    id="major_change_source"
                    value={this.state.major_change_source}
                    options={StaticData.add_minor_sourceList}
                    className={this.state.major_change_source_error + "_select"}
                    isClearable={true}
                    onChange={this.handleChangeSelect.bind(
                      this,
                      "major_change_source"
                    )}
                  />
                </div>
                <p></p>
                <div id="form_why" className={this.state.why_error}>
                  <label
                    for="why"
                    className={this.state.why_error + "_label form-label"}
                  >
                    {this.state.why_title} <span className="red"> *</span>
                  </label>
                  <Select
                    id="why"
                    value={this.state.why}
                    options={StaticData.add_cert_whyList}
                    onChange={this.handleChangeSelect.bind(this, "why")}
                    className={this.state.why_error + "_select"}
                    isClearable={true}
                  />
                </div>
                <br />

                <div id="form_additional_info">
                  <label for="additional_info" className={"_label form-label"}>
                    {this.state.additional_info_title}
                  </label>
                  <textarea
                    className="form-control"
                    id="additional_info"
                    rows="5"
                    onChange={this.handleChange}
                    value={this.state.additional_info}
                    name="additional_info"
                  />
                </div>
              </div>

              <br />
              <br />
              <div id="changeMajorDisclaimer">
                <p>
                  Adding a certificate may impact your tuition and fees. Some
                  programs charge differential or program specific tuition,
                  which is in addition to the base tuition charged each semester
                  you enroll.
                </p>
                <p>
                  Additionally, if you are a sponsored student, receive tuition
                  benefits from a non-ASU entity or accept certain federal
                  funds, adding a certificate may impact your college costs.
                </p>
                <p>
                  Please consult with the financial aid office for details and
                  visit this website:
                  <br />
                  <a
                    href="https://students.asu.edu/tuition"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    https://students.asu.edu/tuition.
                  </a>
                </p>
              </div>
              <br />
              <br />

              <Form
                onSubmit={this.handleSubmit}
                className="searchDegreeSearchBtn"
              >
                <Button className="btn-asu" type="submit">
                  Submit Request to Add Certificate
                </Button>
              </Form>

              <br />
              <br />
              <br />
            </Container>

            {sessionStorage.getItem(serviceauth.SS_VIEW_AS) ? (
              <Container>
                <div className="error_message">
                  You are viewing as student{" "}
                  {sessionStorage.getItem(serviceauth.SS_VIEW_AS)} {". "}
                  <button onClick={this.handleSwitch}> Click </button> to change
                  back to {sessionStorage.getItem(serviceauth.SS_NAME)}
                </div>
              </Container>
            ) : null}

            <Button className="btn wait submitWait nodisplay">
              <FontAwesomeIcon icon={faSpinner} spin /> Submitting Request ...
            </Button>

            <Footer />
          </div>
        ) : null}

        <Button className="btn wait loadWait">
          <FontAwesomeIcon icon={faSpinner} spin /> Loading ...
        </Button>

        <Button className="btn wait resubmitWait nodisplay">
          <FontAwesomeIcon icon={faSpinner} spin /> Submitting Request... Do Not
          Close Browser.
        </Button>
      </Container>
    );
  }
}

export default RequestForm;
